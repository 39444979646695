// @ts-ignore
import { I18nextProvider, useTranslation } from "react-i18next";

function Writing() {
  const { t } = useTranslation();

  return <div>{t("texts.not_implemented")}</div>;
}

export default Writing;
