// @ts-ignore
import { I18nextProvider } from "react-i18next";

import Writing from "./components/Writing";
// @ts-ignore
import i18n from "@projectdiction/translations";

export default function Root(props) {
  return (
    <I18nextProvider i18n={i18n}>
      <Writing />
    </I18nextProvider>
  );
}
